<template>
    <div id="page-lap-penyakit">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_awal"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_akhir"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Asuransi
                    </template>
                    <b-form-select
                      :options="option_asuransi"
                      size="sm"
                      v-model="data_search.asuransi"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">

                    <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tenaga Medis
                    </template>
                    <multiselect
                      :options="options_tenaga_medis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.tenaga_medis"
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                    <b-button variant="warning">Reset</b-button> -->
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5 style="text-align:center"><strong>LAPORAN HARIAN - TINDAKAN TENAGA MEDIS</strong></h5>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-table-simple striped>
                    <b-thead>
                      <b-tr>
                        <b-th>No.</b-th>
                        <b-th>Tanggal</b-th>
                        <b-th>Tindakan</b-th>
                        <b-th>Nama Dokter</b-th>
                        <b-th>Nama Perawat / Bidan / Nutrisionist / Sanitarian</b-th>
                        <b-th>Nama Pasien</b-th>
                        <b-th>No. eRM</b-th>
                        <b-th>Asuransi</b-th>
                        <b-th>Status Bayar</b-th>
                        <b-th>Tarif</b-th>
                        <b-th>Jumlah</b-th>
                        <b-th>Total</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td>{{ idx+1 }}</b-td>
                        <b-td>{{ item.tanggal }}</b-td>
                        <b-td>{{ item.nama_tindakan }}</b-td>
                        <b-td>{{ item.nama_dokter }}</b-td>
                        <b-td>{{ item.nama_perawat }}</b-td>
                        <b-td>{{ item.nama_lengkap }}</b-td>
                        <b-td>{{ item.no_rm }}</b-td>
                        <b-td>{{ item.asuransi }}</b-td>
                        <b-td>{{ item.status_bayar }}</b-td>
                        <b-td>{{ item.jumlah_tarif_tindakan }}</b-td>
                        <b-td>{{ item.jumlah_tindakan }}</b-td>
                        <b-td>{{ item.total_tarif }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="text-align:center" colspan="9"><b>Total</b></b-td>
                        <b-td><b>{{ total_tarif }}</b></b-td>
                        <b-td><b>{{ total_jumlah }}</b></b-td>
                        <b-td><b>{{ total_total }}</b></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "kunjungan_pasien",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        total_tarif: 0,
        total_jumlah: 0,
        total_total: 0,
        options_tenaga_medis: [],
        data_search: {
          tanggal_awal: null,
          tanggal_akhir: null,
          tenaga_medis: null,
          ms_jenis_tenaga_medis_id: null,
          asuransi: null,
          halaman: 0,
          jumlah: 1000,
        },
        option_asuransi: [
          {value: null, text: "Semua"},
          {value: "UMUM", text: "Umum"},
          {value: "BPJS", text: "BPJS Kesehatan"},
          // {value: "", text: "Pemerintah Daerah"},
          // {value: "", text: "Hibah Pemba"},
        ],
        items: [],
        checked: false,
        checked_poli: "",
        
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.data_search.tanggal_awal = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_akhir = this.$moment(new Date()).format("YYYY-MM-DD")
    //   this.getKecamatan()
      this.getData();
      this.getDataKunjungan()
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let tenaga_medis = await vm.$axios.post("/tenaga_medis/list")
        // console.log(tenaga_medis, 'tenaga medis');
        if(tenaga_medis.data.data.length){
            vm.options_tenaga_medis = tenaga_medis.data.data
            // console.log(vm.options_tenaga_medis);
        }
      },
      getDataKunjungan(){
        let vm = this
        if(vm.data_search.tenaga_medis){
          vm.data_search.tenaga_medis_id = vm.data_search.tenaga_medis.tenaga_medis_id
        }
        vm.$axios.post("/tindakan/laporan_harian_tindakan", vm.data_search)
        .then((res) => {
          console.log(res, 'ini respon');
          if(res.data.data.length){
            vm.items = res.data.data
            vm.total_jumlah = 0
            vm.total_tarif = 0
            vm.total_total = 0
            for (let i = 0; i < vm.items.length; i++) {
                let x = vm.items[i];
                x.tanggal = vm.$moment(x.createdAt).format("DD-MM-YYYY HH:mm")
                if(x.status_tagihan_tindakan){
                    x.status_bayar = "Sudah Bayar"
                }else {
                    x.status_bayar = "Belum Bayar"
                }
                vm.total_tarif += x.jumlah_tarif_tindakan
                vm.total_jumlah += x.jumlah_tindakan
                vm.total_total += x.total_tarif
            }
          }else {
            vm.items = []
            vm.$store.commit('set_alert', {msg: "TIDAK ADA DATA", variant: "danger", showing: true})
          }
          
        })
        .catch((err) => {
          vm.$store.commit('set_alert', {msg: err.message, variant: "danger", showing: true})
        })
      },
      toggleSelect: function () {
            let select = this.checked;
            console.log(this.checked);
            this.options_puskesmas.forEach(function (data) {
                data.checked = select;
            });
            this.select_puskesmas = []
            for (let i = 0; i < this.options_puskesmas.length; i++) {
              let x = this.options_puskesmas[i];
              if(x.checked == true){
                this.select_puskesmas.push(x.puskesmas_id)
              }
            }
            this.$forceUpdate()
        },
        toggleSelectPoli: function () {
            let select = this.checked_poli;
            // console.log(this.checked_poli);
            this.options_poli.forEach(function (data) {
                data.checked = select;
            });
            this.select_poli = []
            for (let i = 0; i < this.options_poli.length; i++) {
              let x = this.options_poli[i];
              if(x.checked == true){
                this.select_poli.push(x.ms_poli_id)
              }
            }
            this.$forceUpdate()
        },
        addSelect(){  
          let vm = this
          vm.select_puskesmas = []
          for (let i = 0; i < vm.options_puskesmas.length; i++) {
              let x = vm.options_puskesmas[i];
              if(x.checked == true){
                vm.select_puskesmas.push(x.puskesmas_id)
              }
            }
        },
        addSelectPoli(){  
          let vm = this
          vm.select_poli = []
          for (let i = 0; i < vm.options_poli.length; i++) {
              let x = vm.options_poli[i];
              if(x.checked == true){
                vm.select_poli.push(x.ms_gudang_id)
              }
            }
        },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>
  <style>
  #page-lap-penyakit .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }

  #page-lap-penyakit .custom-control-label{
    padding-top:2.5px
  }
  </style>
  